export const TRANSLATIONS_NO = {
  login: {
    username: 'Brukernavn',
    password: 'Passord',
    login: 'Logg inn',
  },
  header: {
    sales: 'Salgs',
    newMember: 'Nytt Medlem',
    searchMember: 'Søk Medlemmer',
    onlinePayment: 'Online Utbetaling',
    onlinePaymentHistory: 'Online Utbetaling Historikk',
    report: 'Rapport',
    playerWithBalanceReport: 'Spiller med balanse rapport',
    adminReport: 'Admin Rapport',
    openTickets: 'Billetter i dag',
    ticketHistoric: 'Billet Historikk',
    recent: 'Siste salg',
    endShift: 'Avslutte skiftet',
    endShiftMessage: 'Vil du avslutte skiftet?',
    lastTransactions: 'Siste trans.',
    lastCardTransactions: 'Kort trans.',
    specialGame: 'Spesialspill',
    specialGameSubtitle: 'Skriv premiebeløpet',
    specialGameAvailable:
      'Spesialspill er i ferd med å starte, vær oppmerksom på betalingen',
    specialGameMessage: 'Dette vil endre premien for det siste spillet til',
    specialGameMessageConfirm: 'Betalingen har blitt utført vellykket',
    specialGameMessageConfirmNoPrize:
      'Spesialspillet er avsluttet uten vinnere',
    specialGameMessageError: 'Det er ikke noe åpent spesialspill',
    printLastTrans: 'Skriv ut siste salg',
    userLimits: 'Totalgrenser',
  },
  sales: {
    transactions: 'Transaksjoner',
    printAction: 'Skriv Ut',
    scanMessage: 'Skann eller skriv inn brukernummeret',
    search: 'Søk medlemmer',
    searchMemberPin: 'Søk etter pin',
    searchMemberUsername: 'Søk etter brukernavn',
    searchMemberPhonenumber: 'Søk etter telefonnummer',
    autoSearch: 'Automatisk søk',
    searchByLastname: 'Søk etter etternavn',
    searchByFirstname: 'Søk etter fornavn',
    searchBySSNID: 'Søk etter fødselsnummer',
    deposit: 'Sett inn',
    depositSubtitle: 'På spillerkonto',
    withdraw: 'Ta ut',
    withdrawSubtitle: 'Fra spillerkonto',
    transfer: 'Overføring',
    transferSubtitle: 'Til bankkonto',
    newTicket: 'Ny Billett',
    payout: 'Utbetaling',
    scan: 'Skann',
    refill: 'Fylle på',
    product: 'Nytt produkt',
    cash: 'Contant',
    card: 'Kort',
    paymentCard: 'Fullfør kjøpet og betal med kort?',
    paymentCash: 'Fullfør kjøpet og betal kontant?',
    spillerkonto: 'Spillerkonto',
    printMovement: 'Konto-Utskrift',
    saldoUserAccount: ' Saldo på spillerkonto: ',
    newCardMessage:
      'Dette kortet er ikke registrert, vil du opprette en ny bruker?',
    yes: 'Ja',
    no: 'Nei',
    part: 'Delvis',
    partialAmount: 'Velg beløp',
    confirm: 'Bekreft',
    cancel: 'Avbryt',
    cardPaymentItem: 'Kontant uttak',
    confirmWG:
      'For å fortsette må du først fullføre operasjonen i Win Games-kassen.',
    confirmOK:
      'For å fortsette må du først fullføre operasjonen i OK Databingo-kassen.',
    confirmNT:
      'For å fortsette må du først fullføre operasjonen i Norsk Tipping-kassen.',
    NTInn: 'Inn',
    NTUt: 'Ut',
    total: 'Totalt',
    payCard: 'Mottat Kort',
    payCash: 'Mottat Contant',
    change: 'Tilbake',
    remaining: 'Gjenstående',
    paymentCardScreen: 'Betaling med kort',
    paymentCashScreen: 'Betaling med kontanter',
    paymentFullCard: 'Fyll inn hele summen',
    paymentFullCash: 'Fyll inn hele summen',
    amount: 'Beløp',
    endShop: 'Bekrefter du at transaksjonen er utført?',
    endShopByCard:
      'Bekrefter du at transaksjonen er utført? Etter bekreftelse, vent på brukerbetalingen',
    insufficientFunds: 'Transaksjonene er ikke betalt ennå',
    waiting: 'Venter på brukerbetalingen',
    cancelShop: 'Avbryt kjøp',
    print: 'Skriv ut kvittering',
    shortcuts: 'Hurtigknapper beløp',
    payCard: 'Betal med kort',
    payCardSingle: 'Enkel Betal med kort',
    payCash: 'Betal med Cash',
    payAccount: 'Betal med konto',

    payCHTitle: 'Betal med Spillerkonto',
    payCH: 'Spiller-Konto',
    payoutTicket: 'Åpne billetter',
    registerNewAnonUser: 'Ny anonym bruker',
    registerNewHybridUser: 'Ny daglig bruker',
    registerNewMember: 'Ny medlem',
    editHybridUser: 'Rediger daglig bruker',
    editMember: 'Rediger medlem',

    anonToUser:
      'Vil du redigere denne daglig brukeren eller gjøre den om til medlem?',
    hybridToUser:
      'Vil du redigere denne daglig brukeren eller gjøre den om til medlem?',
    optional: '(valgfri)',
    userTooSmall: 'Søk skal ha minst 7 sifre',
    userNotFound: 'Brukeren eksisterer ikke, vil du opprette en ny? ',
    usersFound: 'Mer enn en bruker er funnet',
    selectUser: 'Bruk denne brukeren',
    registerNewUserConfirm: 'Registrer ny bruker',
    editUserConfirm: 'Rediger bruker',
    editUserConfirmButton: 'Rediger',
    phonenumber: 'Telefonnummer',
    phonenumberMandatory: 'Telefonnummer (påbudt)',
    newUser: 'Ny dadlig bruker',
    newMember: 'Nytt medlem',
    newMemberText:
      'Kunde må bekrefte sin identitet, spør kunde hvilken metode vedkommende ønsker å bruker for å bekrefte sin identitet',
    newMemberButtonID: 'ID-Kort, Førerkort eller Pass',
    newMemberButtonBankID: 'BankID',
    updateHybridUser:
      'Dette er en daglig bruker, du kan redigere den eller registrere den som et nytt medlem:',
    lastUsers: 'Siste brukere',
    sellMessage: 'Husk å motta kontanter fra kunden før du trykker TOTAL',
    sellMessageCash1: 'Husk å ta ',
    sellMessageCash2: ' KR fra kunden. ',
    sellMessageReturnCash1: 'Husk å gi tilbake ',
    sellMessageReturnCash2: ' KR til kunden.',
    name: 'Navn',
    nameMandatory: 'Navn (påbudt)',
    surname: 'Etternavn',
    nameAndSurname: 'Navn og etternavn',
    unblock: 'Send bekreftelse til brukeren for å låse opp billettene',
    send: 'Send',
    unregistered: 'Uregistrert',
    registerAnonPlayer: 'Registrer den daglige brukeren',
    paymentAnon:
      'Daglige brukere kan kun betale med kort, vil du registrere denne daglige brukeren?',
    registerAnonPlayerConfirm: 'Registrer',
    transactionDone: 'Transaksjonen er fullført',
    backToPayment: 'Tilbake til betalingen',
    transactionFail: 'Transaksjonen mislyktes eller avvistes',
    smsTitle: 'Venter på bekreftelseskoden',
    smsText1:
      'En SMS med en bekreftelseskoden har blitt sendt til kundens mobilnummer for å bekrefte identiteten.',
    smsText2: 'Kunden må bekrefte pinkoden på skjermen.',
    pincodeTitle: 'Venter på brukerens pinkode',
    pincodeText1:
      'Brukeren bør introdusere den 4-sifrede pinkoden knyttet til kontoen sin: ',
    userConfirm: 'Bruker registrert vellykket',
    selected: 'Valgt',
    internetAccess: 'Forby internettilgang',
    chooseOne: 'Velg...',
    passport: 'Pass',
    driverLicense: 'Førerkort',
    electronicID: 'Electronic(BankID)',
    nationalID: 'Nasjonalt ID-kort',
    bankCard: 'Bankkort',
    other: 'Annet',
    terms: 'Vilkår for bruk og personvernerklæring',
    communication: 'Valg av kommunikasjonkanaler vi kan bruker',
    pincode: 'Uttakspinkode',
    newPincode: 'Ny pinkode',
    pincodeLabel: 'Uttakspinkode',
    cardID: 'Kort ID',
    nfcCardID: 'NFC Kort ID',
    ssnid: 'Fødselsnummer',
    username: 'Brukernavn',
    firstname: 'Fornavn',
    lastname: 'Etternavn',
    address: 'Adresse',
    verificationMethod: 'Verifiseringsmetode',
    country: 'Land',
    postalCode: 'Postnummer',
    region: 'Sted',
    email: 'E-post',
    birthdate: 'Fødselsdato',
    verificationID: 'Verifiserings-ID',
    cashoutPending: 'Brukeren har en ventende utbetaling:',
    cashoutNow: 'Utbetale nå',
    signatureTitle: 'Signatur kreves',
    signatureText:
      'Gjeldende transaksjon må signeres for å bli godkjent. Venter på brukerens signatur.',
    signatureTitleID: 'ID-bekreftelse påkrevd',
    signatureTextID:
      'En ID-bekreftelse er påkrevd for å fortsette. Ta et bilde av brukerens ID for å verifisere brukerens identitet.',
    signatureConfirmSMS: 'En pinkode vil bli sendt til brukerens telefon',
    signatureUserNeedsMobile:
      'Denne brukeren har ikke registrert et telefonnummer. Et telefonnummer er obligatorisk for å fortsette. ',
    signatureConfirm: 'Transaksjoner godkjent',
    openSessionTitle: 'Åpen økt funnet',
    openSessionText1: 'Denne billetten har en åpen økt i terminalen ',
    openSessionText2: ' med en total mengde ',
    openSessionTextClose: 'Vil du lukke økten?',
    openSessionTextUnclosable: 'Denne økten må lukkes i terminalen først',
    chooseProduct: 'Velg produkt',

    ticket7Pincode: 'Billet Pincode',
    currentSaldo: 'Gjeldende saldo',
    editHybridToFull: 'Gjør om til medlem',
    userLimits: 'Totalgrenser',
    noLimits: 'Ingen begrensning',
    cashIn: 'Innskudd',
    cashOut: 'Uttak',
    limitsUpdated: 'Begrensningene for denne brukeren ble oppdatert',
    cardToCash: 'Kontant uttak',
    limitDaily: 'Daglig grense',
    limitMonthly: 'Månedlig grense',
    isLimitSurpassed: 'Grensen er overskredet',
    isLimitSurpassedDaily:
      'Denne brukeren har overskredet grensen og har ikke lov til å spille mer i dag',
    isLimitSurpassedMonth:
      'Denne brukeren har overskredet grensen og har ikke lov til å spille mer denne måneden',
    isVerificationNeeded: 'Verifisering er nødvendig',
    isVerificationNeededText:
      'Brukeren må verifiseres for å fortsette, rediger brukeren for å verifisere den.',
    isLimitSurpassedTransaction1:
      'Den nåværende transaksjonen er ikke tillatt fordi den overstiger brukerens grense. ',
    isLimitSurpassedTransaction2: 'Det maksimale beløpet som er tillatt er: ',

    ticketWithDifferentParentTitle:
      'Denne billetten tilhører ikke denne brukeren',
    ticketWithDifferentParentText:
      'Denne billetten tilhører en annen bruker, vil du foreta utbetalingen til den nåværende brukeren?',
    photoVerificationTitle: 'Bekreft kundens identitet',
    photoVerificationText:
      'Bruk profilbildet for å bekrefte kundens identitet, ta et nytt bilde, be kunden om å taste inn PIN-koden for uttak eller bekreft at du kjenner kunden for å fortsette.',
    photoVerificationTextHybrid:
      'Bruk profilbildet for å bekrefte kundens identitet, ta et nytt bilde eller bekreft at du kjenner kunden for å fortsette.',
    photoUploadTitle: 'Ta bildet',
    photoUploadText:
      'Bruk et av kameraene til å ta et bilde av brukerens ansikt eller et annet gyldig dokument, som pass eller ID-kort.',
    photoUploadTextSignature:
      'Bruk et av kameraene til å ta et bilde av brukerens gyldig dokument, som pass eller ID-kort.',
    photoUploadSuccess: 'Bildet ble lastet opp',
    userVerificationImage: 'Brukerverifiseringsbilde',
    userVerificationImageNew: 'Ta et nytt bilde',
    saldo: 'Saldo',
    status: 'Status',
    open: 'Åpen',
    closed: 'Lukket',
    inGame: 'I spill',
    editUserBlock: 'Fullfør den nåværende operasjonen for å redigere brukeren',
    bigNumberConfirmTitle: 'Transaksjon over 10.000',
    bigNumberConfirmText1:
      'Beløpet for denne transaksjonen er høyere enn vanlig, vil du fortsette?',
    bigNumberConfirmText2: 'Totalbeløp: ',
    deleteUser: 'Slett',
    banUser: 'Deaktiver',
    banUserTitle: 'Deaktiver bruker',
    banUserText:
      'Er du sikker på at du vil deaktivere denne brukeren? Velg årsaken og hvor lenge du vil deaktivere brukeren.',
    banUserReason: 'Årsak',
    banUserReasonPlaceholder: 'Velg en årsak',
    banUserDuration: 'Varighet',
    banUserDurationPlaceholder: 'Velg en varighet',
    deleteUserTitle:
      'Du er i ferd med å slette denne brukeren, er du sikker på at du vil fortsette?',
    deleteUserConfirm: 'Brukeren har blitt slettet',
    closeSellerOrPayment:
      'Fullfør den nåværende operasjonen eller gå tilbake til hovedskjermen for å skanne en ny billett',
    invalidTicket: 'Ugyldig billett',
    userSignatureCheckTitle: 'Identitetskontroll kreves',
    userSignatureCheckText:
      'Be kunden om et gyldig dokument for å verifisere signaturen. Husk at du må ta et bilde av dokumentet hvis du godkjenner signaturen.',
    newSpecialGameLuckyDay: "Ny 'Lucky Day' rulett",
    newSpecialGameLuckyDayText1:
      "En ny 'Lucky Day' rulett har blitt spilt i denne hallen.",
    newSpecialGameLuckyDayText2:
      'Brukeren må matche nummeret på ruletten med dagens dato for å vinne premien.',
    newSpecialGameLuckyDayText3: 'I dag er: ',
    newSpecialGameLuckyDayText4: 'Har brukeren fått riktig nummer?',
    restart: 'Starte på nytt',
    switchCamera: 'Bytt kamera',
    specialGameMessageNoPrizeConfirm:
      'Brukeren har ikke vunnet spesialpremien, og spillet vil betale standard bingopremie og bildes og rammes hvis de finnes. Vil du fortsette?',
    termsSignature:
      'For å fortsette og lagre brukerens signatur, må brukeren godta personvernreglene og akseptere at ID-en vil bli lagret i våre systemer i opptil to år.',
    signatureStored: 'Signatur lagret',
    signatureStoredText:
      'Brukeren har allerede en lagret gyldig identifikasjon, sjekk brukerens identitet og bekreft for å fortsette transaksjonen.',
    signatureStoredDate: 'Identifikasjon lagret den',
    postponeIdentification: 'Utsett identifikasjon',
    postponeIdentificationText:
      'Utsett identifikasjonen for denne transaksjonen og fortsett med betalingen (brukeren må verifisere identifikasjonen innen de neste 2 ukene.)',
    ticketClosedOK: 'Billetten er allerede lukket',
    waitingForScan: 'Venter på kortskanning',
    waitingForScanText:
      'Skann ett av CH-kortene for å knytte det til denne brukeren automatisk',
    pep: 'P.E.P. eller nært familiemedlem med en P.E.P.*',
    waitingForUser: 'Venter på bruker',
    waitingForUserTerms: 'Brukeren må godkjenne brukervilkårene',
    waitingForRiskyInfo: 'Brukeren må gi ekstra informasjon',
    waitingForUserCommunication:
      'Brukeren må velge kommunikasjonskanaler vi kan bruke',
    waitingForUserPEP:
      'Brukeren må bekrefte om han er en P.E.P. og fylle ut skjemaet',
    waitingForUserPincodeTitle: 'Venter på brukerens pinkode',
    waitingForUserPincodeText:
      'Brukeren bør introdusere den 4-sifrede pinkoden knyttet til kontoen sin',
    newMemberBankID:
      'Kunden vil motta en SMS med en lenke for å registrere seg med BankID.',
    smsSent:
      'En SMS med en lenke for å registrere og identifisere brukeren har blitt sendt til brukerens telefonnummer',
    importantTitle: 'Viktig kunngjøring',
    importantSubtitle:
      'Fra og med 1. januar 2025 må alle daglige brukere være registrert med gyldig ID som medlemmer. Hvis brukeren ikke kan registrere seg med gyldig ID akkurat nå, fortsett ved å avbryte denne kunngjøringen.',
    iKnowTheUser: 'Kjenner kunden eller ID OK',
    identityConfirm: 'Bekreft brukerens identitet',
    countryOfResidence: 'Land',
    confirmPicture: 'Bekreft bilde',
    continueWithoutRegister: 'Fortsett uten å registrere',
    registerAsMember: 'Registrer som medlem',

    waitingForApproval: 'Venter på godkjenning',
    waitingForApprovalText:
      'Denne brukeren trenger manuell godkjenning, kontakt support.',
    suspectedFraud: 'Mistenkt svindel',
    maxFailedLoginAttempts: 'Maks antall mislykkede påloggingsforsøk',
    incorrectPersonalInformation: 'Feil personlig informasjon',
    otherBanned: 'Andre',
    underaged: 'Under 18 år',
    hrl: 'HRL',
    pepBanned: 'PEP',
    badBehaviour: 'Dårlig oppførsel',
    selfExclusionFor1Month: 'Selvutestengelse i 1 måned',
    selfExclusionFor3Months: 'Selvutestengelse i 3 måneder',
    selfExclusionFor6Months: 'Selvutestengelse i 6 måneder',
    selfExclusionFor1Year: 'Selvutestengelse i 1 år',
    deletedAccount: 'Slettet konto',
    userIsNotAllowed: 'Brukeren er sperret',
    time1week: '1 uke',
    time1month: '1 måned',
    time3months: '3 måneder',
    time6months: '6 måneder',
    time1year: '1 år',
    riskyCountryTitle: 'Du må oppgi tilleggsinformasjon',
    riskyCountry:
      'Du er fra et land som krever manuell godkjenning og ekstra informasjon for å fortsette.',
    residence: 'Bosted',
    reason: 'Grunn: ',

    confirmLogin: 'Bekreft kundens identitet',
    confirmLoginText:
      'Bruk profilbildet for å bekrefte kundens identitet, ta et nytt bilde, be kunden om å taste inn PIN-koden for uttak eller bekreft at du kjenner kunden for å fortsette.',
    confirmLoginNewMember: 'Nytt bilde',
    confirmLoginPincode: 'Uttakspinkode',
    confirmLoginIKnowTheUser: 'Kjenner kunden eller ID OK',
    sendPincodeBySMS: 'Send pinkode via SMS',
    sendPincodeBySMSText:
      'Brukerens PIN-kode har blitt sendt til brukerens telefonnummer',
    createNewUser: 'Nytt medlem',
    sendOneTimeCodeBySMS: 'Engangskode via SMS',
    newConfirmTitle: 'Brukeren har bekreftet sin identitet',
    newConfirmText:
      'Brukeren har brukt sin uttak PIN-kode for å bekrefte sin identitet, trykk på bekreft for å fortsette',
    sellBlockedTitle: 'Brukeren har åpne billetter',
    sellBlockedText:
      'Brukeren har åpne billetter og har ikke lov til å kjøpe flere billetter før de åpne billettene er lukket',
    sellBlockedOpenTickets: 'Åpne billetter:',
  },
  pep: {
    pep: 'PEP',
    customerIsPep: 'Kunden er en PEP',
    customerIsNotPep: 'Kunden er ikke en PEP',
    customerRelativeIsPep: 'Kundens nære familiemedlem er en PEP',
    pepQuestion: 'Er du en politisk eksponert person (PEP)?',
    pepDescription:
      'Du er en PEP hvis du (eller noen i din nærmeste familie) har, eller har hatt, et høytstående verv eller stilling i Norge eller utlandet.',
    pepUncertain: 'Er du usikker på hvilke verv og stillinger?',
    checkList: 'Sjekk listen her',
    positions: [
      'Statsoverhode, regjeringssjef, minister eller assisterende minister',
      'Medlem av nasjonalforsamling',
      'Medlem av styrende organ i politisk parti',
      'Medlem av høyere rettsinstans som treffer beslutning som ikke er bare unntaksvis kan ankes',
      'Medlem av styre i riksrevisjon, revisjonsdomstol eller sentralbank',
      'Amassadør, chargé d’affaires eller militær offiser av høyere rang',
      'Medlem av administrativt, ledende eller kontrollerende organ i statlig foretak',
      'Direktør, styremedlem eller annen person i øverste ledelse i internasjonal organisasjon',
    ],
    pepYes: 'Jeg er PEP',
    pepNo: 'Jeg er ikke PEP',
    tellWhoIsPep: 'Fortell oss hvem som er PEP',
    pepForm: 'PEP-skjema',
    relationshipToPep: 'Din relasjon til PEP',
    name: 'Navn',
    birthDate: 'Fødselsdato',
    gender: 'Kjønn',
    fundsQuestion: 'Hvilke midler skal du spille for?',
    funds: [
      'Lønn',
      'Salg/utleie av eiendom',
      'Salg av aksjer eller fond',
      'Trygd, pensjon, studielån eller andre støtteordninger',
      'Gaver, arv eller lignende',
    ],
    consent: 'Samtykke',
    consentStatements: [
      'Jeg bekrefter at bankkontoer knyttet opp mot min spillekonto for innskudd/inntak står i mitt navn',
      'Jeg bekrefter at midlene jeg skal spille for, inkludert kontanter, er lovlig anskaffet og beskattet',
      'Jeg bekrefter å selv rapportere saldo på spillekonto til myndigheter ved levering av skattemelding',
    ],
    male: 'Mann',
    female: 'Kvinne',
    other: 'Annet',
    required:
      'Du må godta alle samtykker for å fortsette og velge både relasjonen og midlene',
    riskyRequired:
      'Du må godta alle samtykker for å fortsette og velge hvor midlene dine kommer fra',
    userNotReady: 'Brukeren er ikke klar til å fortsette',
  },
  communication: {
    title: 'Hvor kan vi nå deg?',
    subtitle:
      'Velg hvor du ønsker å få nyttig informasjon om gevinst, og annet tilpasset innhold.',
    option1: 'SMS',
    option2: 'Epost',
    option3: 'Få tilpassede tilbud og nyheter på sosiale medier',
    option4: 'Velg alle ovenfor',
  },
  terms: {
    title: 'Vilkår for bruk og personvernerklæring',
    termsText1: 'Jeg har lest og godtar Bingo 1 sine ',
    termsText2: 'vilkår for bruk og personvernerklæring',
    accountText: 'Spillekontoen skal kun benyttes av meg *',
    required: 'Du må godta vilkårene for å fortsette',
  },
  error: {
    empty: 'Felter skal ikke være tomme',
    login: 'Brukenavn eller passord er feil',
    blockProvider:
      'Det er ikke mulig å selge billetter fra to forskjellige databingoleverandører for samme bruker eller mer enn en billett',
    userNotLogin: 'Du må logge på en bruker først',
    userNotMember: 'Denne brukeren er ikke medlem av ClubHouse',
    error:
      'Noe gikk galt. Prøv igjen om et øyeblikk eller kontakt brukerstøtten.',
    errorTooSmall: 'Søket med pinne må ha minst 7 sifre',
    errorPhonenumber: 'Søket etter telefonnummer må være 7 sifre',
    errorPhonenumberInput:
      'Telefonnummeret er ikke gyldig. Bruk et gyldig norsk 8-sifret nummer',
    errorUsernameInput: 'Brukernavn kan ikke være tomt',
    errorCardIDEmpty: 'Kort-ID kan ikke være tom',
    errorCardIDFormat: 'Kort-ID-formatet er feil',
    errorUsernameEmpty: 'Brukernavnet kan ikke være tomt',
    errorUsernameTooShort: 'Brukernavnet er for kort, minimum 5 tegn',
    errorUsernameTooLong: 'Brukernavnet er for langt, maksimum 12 tegn',
    errorFirstnameEmpty: 'Fornavnet kan ikke være tomt',
    errorLastnameEmpty: 'Etternavnet kan ikke være tomt',
    errorAddress1Empty: 'Adressen kan ikke være tom',
    errorPostalCodeEmpty: 'Postnummer kan ikke være tomt',
    errorRegionEmpty: 'Regionen kan ikke være tom',
    errorEmailEmpty: 'E-post kan ikke være tom',
    errorBirthdateEmpty: 'Fødselsdatoen kan ikke være tom',
    errorBirthdateFormat: 'Fødselsdato formatet er feil',
    errorVerificationIDEmpty: 'Bekreftelses-ID kan ikke være tom',
    errorVerificationMethodEmpty: 'Bekreftelsesmetoden kan ikke være tom',
    errorPhonenumberExists: 'Telefonnummer allerede registrert',
    errorUsernameExists: 'Brukernavn allerede registrert',
    errorEmailExists: 'E-post allerede registrert',
    errorCardIDExists: 'Kort-ID allerede registrert',
    errorNfcCardIDExists: 'NFC Kort-ID allerede registrert',
    errorSSNIDExists: 'Fødselsnummer allerede registrert',
    errorUserFailCode: 'Koden introdusert av brukeren er ikke korrekt',
    errorRefreshTransactions:
      'Du kan ikke starte kassereren på nytt med ventende transaksjoner',
    errorNavigateTransactions:
      'Du kan ikke bytte skjerm med en bruker som er logget inn eller med pågående transaksjoner',
    errorCode: 'Feilkode',
    errorLimitSize:
      'Den daglige grensen kan ikke være lavere enn den månedlige grensen',
    errorEmptyLimits: 'Begrensningene kan ikke være tomme',
    errorImageRegister:
      'Du må verifisere brukerens bilde før du fullfører registreringen',
    errorSSNIDInvalid: 'Fødselsnummer er ikke gyldig',
    errorSSNIDUnderAge: 'Brukeren er under 18 år',
    errorCancelOpenSell:
      'For å kansellere den valgte transaksjonen, må du fullføre dette salget eller gå tilbake til hovedskjermen',
    errorPEP:
      'Brukeren må bekrefte om han er en P.E.P. eller nært familiemedlem av en P.E.P. for å fortsette',
    errorTermsEmpty: 'Brukeren må godta vilkårene for å fortsette',
    errorCommunicationEmpty:
      'Brukeren må velge kommunikasjonskanaler vi kan bruke',
    errorPincodeEmpty: 'Pinkoden kan ikke være tom',
    errorRiskyEmpty: 'Brukeren må gi ekstra informasjon for å fortsette',
  },
  sisteSalgs: {
    date: 'Dato',
    bingoName: 'Hall',
    operator: 'Operatør',
    payCard: 'Betalt med kort',
    payCash: 'Betalt med Cash',
    payAccount: 'Betalt med konto',
    total: 'Totalt',
    change: 'Tilbake',
    userID: 'Bruker ID',
    ticket7Pincode: 'Billet Pincode',
    ticketID: 'Billet ID',
    username: 'Brukernavn',
    amount: 'Beløp',
    printed: 'Trykt',
    transactions: 'Se transaksjoner',
    description: 'Beskrivelse',
    printAgain: 'Skriv ut',
    backToSells: 'Tilbake til salg',
  },
  sisteTrans: {
    date: 'Dato',
    bingoName: 'Hall',
    operator: 'Operatør',
    payCard: 'Betalt med kort',
    payCash: 'Betalt med Cash',
    total: 'Totalt',
    change: 'Tilbake',
    ticket7Pincode: 'Billet Pincode',
    ticketID: 'Billet ID',
    username: 'Brukernavn',
    amount: 'Beløp',
    printed: 'Trykt',
    transactions: 'Se transaksjoner',
    description: 'Beskrivelse',
    printAgain: 'Skriv ut',
  },
  ticketHistoric: {
    ticketToSearch: '7-sifret billett for å søke',
    date: 'Dato',
    action: 'Aksjon',
    openSession: 'Åpnet økt',
    closeSession: 'Lukket økt',
    payment: 'Kjøp kort',
    win: 'Vinn premie',
    cancel: 'Avbryt kort',
    deposit: 'Innskudd',
    depositFromPreviousSession: 'Innskudd fra forrige økt',
    saldoInit: 'Startbalanse',
    saldoEnd: 'Endelig balanse',
    bingoName: 'Hall',
    gameID: 'Spill-ID',
    valueChange: 'Verdiendring',
  },
  apneBilleter: {
    date: 'Dato',
    bingoName: 'Hall',
    operator: 'Operatør',
    ticket7Pincode: 'Billet Pincode',
    ticketID: 'Billet ID',
    user7Pincode: 'Bruker',
    description: 'Beskrivelse',
    amount: 'Beløp',
    printAgain: 'Skriv ut',
    seeTransactions: 'Se transaksjoner',
    username: 'Brukernavn',
  },
  menu: {
    search: 'Søk',
  },
  report: {
    startDay: 'Det er ikke åpent økten. Start et nytt nå.',
    startDayButton: 'Start økten.',
    confirm: 'Bekreft',
    edit: ' Rediger',
    cashin: 'Innskudd',
    cashout: 'Uttak',
    neto: 'Neto',
    next: 'Neste',
    back: 'Tilbake',
    picture: ' Bilde',
    errorTicket: 'Du må laste opp billettene først.',
    capturePicture: 'Ta bilde',
    resetCamera: 'Tilbakestill kamera',
    date: 'Dato',
    employee: 'Ansatt',
    miniSafeYesterday: 'Minisafe i går',
    cashierYesterday: 'Kassebeholding i går',
    cashierInOpening: 'Kassebeholdning ved åpning',
    difference: 'Differanse',
    cashierTotal: 'Kasse totalt',
    cashierRefill: 'Kassepåfyll',
    sendReport: 'Send rapport',
    theoric: 'Teoretisk',
    minisafe: 'Minisafe',
    cashier: 'Kassebeholding',
    deviated: 'Avvik',
    acceptDeviated: 'Godkjenner avvik',
    closeReport: 'Dagsrapport slutt',
  },
  endShift: {
    title: 'Velg ett av alternativene:',
    subtitle:
      'Du må gjøre begge deler, kortterminalrapporten og daglig kasserapport',
    cardReport: 'Kortterminal rapport',
    cashierReport: 'Clubhouse Rapport',
    winGamesReport: 'Win Games Rapport',
    completed: 'Fullført',
    notCompleted: 'Ikke fullført',
    confirmCardReport: 'Vil du bekrefte kortterminalrapporten?',
    confirmWinGamesReport: 'Vil du bekrefte Win Games rapporten?',
    cashin: 'Sett inn penger',
    cashout: 'Ta ut penger',
    neto: 'Netto',
    cardPayment: 'Betaling med kort',
    cardPayout: 'Kontant uttak',
    cardTotal: 'Totalt med kort',
    waiting: 'Behandler rapporten, vent et øyeblikk.',
  },
  socketStatus: {
    disconnectTitle: 'Tilkobling tapt',
    disconnectText:
      'Tilkoblingen til serveren har blitt brutt. Prøver å koble til igjen, vent eller kontakt support.',
    reconnectTitle: 'Tilkoblet igjen',
    reconnectTextNoTransactions:
      'Tilkoblingen til serveren har blitt gjenopprettet. Vi anbefaler at du oppdaterer kassen.',
    reconnectTextTransactions:
      'Tilkoblingen til serveren har blitt gjenopprettet. Vi anbefaler at du oppdaterer kassen etter å ha fullført de pågående transaksjonene.',
    refresh: 'Oppdater nå',
    notRefresh: 'Ikke oppdater',
  },
};
